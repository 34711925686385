import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs/operators'
import { ApiService } from '../shared/services/api.service'
import { CookieService } from 'ngx-cookie-service'

export const AFFILIATES_COOKIE = 'lainasto_affiliate_test'
export const AFFILIATES_COOKIE_USED = 'affiliatesUsed'

@Injectable()
export class CustomerNavigationService {
  private readonly sessionStorage = 'initialCustomerSession'
  private session: string = ''

  constructor(private router: Router, private api: ApiService, private cookieService: CookieService) {
    if (!this.session) {
      this.session = sessionStorage.getItem(this.sessionStorage) ?? ''
      if (!this.session) {
        const session = this.generateSession()
        this.setSession(session)
      }
    }
  }

  init() {
    const affiliateSet = this.cookieService.get(AFFILIATES_COOKIE_USED)
    const affiliateData = affiliateSet ? '' : this.cookieService.get(AFFILIATES_COOKIE)
    const cid = this.cookieService.get('cid')
    const sourceCookie = this.cookieService.get('initialTrafficSource')
    const source = {}
    if (sourceCookie) {
      sourceCookie.split('|').forEach(record => {
        const [utmKey, utmValue] = record.split('=')
        source[utmKey] = utmValue
      })
    }
    if (!affiliateSet) {
      this.api.trackCustomerData(cid, this.session, affiliateData, { source }).subscribe()
    }

    // istanbul ignore next
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      if (event.urlAfterRedirects.startsWith('/customer-details')) {
        if (!affiliateSet) {
          this.api.trackCustomerData(cid, this.session, affiliateData, { source }).subscribe()
          this.cookieService.set(AFFILIATES_COOKIE_USED, 'true', { expires: 365 })
        }
      }
      this.api.trackCustomerNavigation(this.session, event).subscribe()
    })
  }

  generateSession(): string {
    return [...Array(32)].map(() => (Math.random() + new Date().getTime()).toString(36).slice(-1)).join('')
  }

  getSession(): string {
    return this.session
  }

  resetSession() {
    sessionStorage.removeItem(this.sessionStorage)
  }

  setSession(session: string) {
    this.session = session
    sessionStorage.setItem(this.sessionStorage, this.session)
  }
}
