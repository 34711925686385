import { APP_INITIALIZER, NgModule } from '@angular/core'
import { InitService } from './shared/services/init.service'

@NgModule({
  providers: [
    InitService,
    {
      provide: APP_INITIALIZER,
      useFactory: /*istanbul ignore next*/ (initService: InitService) => () => initService.init(),
      deps: [InitService],
      multi: true,
    },
  ],
})
export class InitModule {}
