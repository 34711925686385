import { AfterViewInit, Component, Inject } from '@angular/core'
import { ConfigService } from '../../../shared/services/config.service'
import { environment } from '../../../../environments/environment'
import { DOCUMENT } from '@angular/common'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AppSelectors } from '../../../store/app.selectors'

@Component({
  selector: 'app-flow-layout',
  templateUrl: './flow-layout.component.html',
  styleUrls: ['./flow-layout.component.scss'],
})
export class FlowLayoutComponent implements AfterViewInit {
  env = environment
  noticeVisibleSelector$: Observable<boolean>

  // istanbul ignore next
  constructor(public config: ConfigService, @Inject(DOCUMENT) private document: Document, private store: Store) {
    this.noticeVisibleSelector$ = store.select(AppSelectors.noticeVisibleSelector)
  }

  ngAfterViewInit() {
    this.document.body.classList.remove('theme-dark')
  }
}
