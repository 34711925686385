// istanbul ignore file
import { NgModule } from '@angular/core'
import { RouterModule, Routes, PreloadAllModules } from '@angular/router'
import { ErrorPageComponent } from './mypages/error-page/error-page.component'
import { FlowLayoutComponent } from './flow/shared/flow-layout/flow-layout.component'
import { AuthGuard } from './shared/guards/auth.guard'

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./flow/flow.module').then(m => m.FlowModule),
  },
  {
    path: 'mypages',
    loadChildren: () => import('./mypages/mypages.module').then(m => m.MypagesModule),
    canLoad: [AuthGuard],
  },
  {
    path: '**',
    component: FlowLayoutComponent,
    children: [{ path: '', component: ErrorPageComponent }],
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      useHash: false,
      anchorScrolling: 'enabled',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
