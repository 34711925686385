<div class="p-2 mt-2">
  <div style="float: left; width: 3.5em; margin-right: 1em; margin-top: 1em">
    <img src="/assets/images/notice.svg" alt="" />
  </div>
  <div class="d-flex small pt-2">
    <div>
      <strong>{{ 'NoticeComponent.header' | translate }}</strong>
      <p [innerHTML]="'NoticeComponent.text' | translate"></p>
    </div>
  </div>
</div>
