import { Environment } from './interface'
import { WhiteLabels } from '../app/shared/types'

export const environment: Environment = {
  apiHost: 'https://dev.gfmoney.se',
  appHost: 'https://app-dev.gfmoney.se',
  brand: WhiteLabels.gfmoney,
  brandName: 'GF Money',
  creditInformation: 'https://gfmoney.se/sekki',
  email: 'info@gfmoney.se',
  environment: 'dev',
  gdprPolicy: 'https://gfmoney.se/integritetspolicy',
  options: {
    interest: '44 %',
  },
  paymentProviderClientId: '6dac77b2-e908-36b5-010a-f48da33c5c0e',
  phone: '08 - 50 23 7000',
  plusgiro: '480 34 01-1',
  production: false,
  regCode: '',
  serviceId: '19',
  servicesUrl: 'https://services.lainasto.fi',
  termsAndConditions: 'https://gfmoney.se/villkor',
  tinkClientId: '96f066717e684b22aefa68d9ea436413',
}
