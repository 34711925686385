import { Injectable } from '@angular/core'
import { Route, Router, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '../services/auth.service'

@Injectable()
export class AuthGuard {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    return this.auth.isAuthenticated() ? true : this.router.parseUrl('/mypages/login')
  }

  canLoad(route: Route): boolean {
    // istanbul ignore next
    return route.path!.startsWith('mypages') ?? false
  }
}
