import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  // @ts-ignore
  private readonly _env

  constructor() {
    this._env = new Proxy(environment, {
      get(target, prop) {
        if (prop.toString().indexOf('#') !== -1) {
          const p = prop.toString().split('#')
          // @ts-ignore
          return `${target[p[0]]}${p[1]}`
        }

        if (prop.toString().indexOf('_') !== -1) {
          const p = prop.toString().split('_')
          // @ts-ignore
          return `${p[0]}:${target[p[1]]}`
        }
        // @ts-ignore
        return target[prop]
      },
    })
  }

  getSetting(setting: string) {
    return this._env[setting]
  }
}
