import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'account',
})
export class AccountPipe implements PipeTransform {
  transform(account: string): string {
    return account.replace(/\D/g, '')
  }
}
