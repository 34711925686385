import { BrowserModule } from '@angular/platform-browser'
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE, InjectionToken } from '@angular/core'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { NgxMaskModule } from 'ngx-mask'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CookieService } from 'ngx-cookie-service'
import { NgxGfmoneyMessagesModule } from '@yuri.karkhachev/ngx-gfmoney-messages'

import { registerLocaleData } from '@angular/common'
import localeSv from '@angular/common/locales/sv'
registerLocaleData(localeSv, 'sv')

import { defineLocale } from 'ngx-bootstrap/chronos'
import { svLocale } from 'ngx-bootstrap/locale'
import { ModalModule } from 'ngx-bootstrap/modal'
import { InitModule } from './init.module'
import { CustomerNavigationModule } from './customer-navigation/customer-navigation.module'
import { ApiInterceptor } from '@shared/services/api.interceptor'
import { AccountPipe } from '@shared/pipes/account.pipe'
import { ErrorPageComponent } from './mypages/error-page/error-page.component'
import { StoreModule } from '@ngrx/store'
import { appReducer } from './store/app.reducer'
import { NgxGfmoneyLibModule } from '@yuri.karkhachev/ngx-gfmoney-lib'
import { environment } from '@environments/environment'
defineLocale('sv', svLocale)

// istanbul ignore next
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

export const WindowToken = new InjectionToken<Window>('Window object', {
  providedIn: 'root',
  factory: () => window,
})

const INTERCEPTOR_PROVIDER = {
  provide: HTTP_INTERCEPTORS,
  useClass: ApiInterceptor,
  multi: true,
}

@NgModule({
  declarations: [AppComponent, ErrorPageComponent],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CustomerNavigationModule,
    InitModule,
    ModalModule.forRoot(),
    NgxGfmoneyLibModule.forRoot({
      servicesUrl: environment.servicesUrl,
    }),
    NgxGfmoneyMessagesModule.forRoot({
      allowedFileTypes: 'image/jpeg,image/png,.pdf,.txt',
      apiUrl: environment.apiHost,
      brand: environment.brandName,
      dateFormat: 'yyyy-MM-dd HH:mm',
      homePagePath: '/mypages',
    }),
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'sv',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot(
      {
        app: appReducer,
      },
      {},
    ),
  ],
  providers: [
    AccountPipe,
    CookieService,
    { provide: LOCALE_ID, useValue: 'sv' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'kr' },
    INTERCEPTOR_PROVIDER,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
