import { Injectable, OnDestroy } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Bank, CustomerExtended } from '../interfaces'
import { Observable, Subject, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { LoginTypes } from '../types'

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  error$: Subject<string> = new Subject<string>()
  login$: Subject<boolean> = new Subject<boolean>()
  logout$: Subject<boolean> = new Subject<boolean>()

  constructor(private http: HttpClient) {}

  get user(): CustomerExtended | null {
    const user = sessionStorage.getItem('user')
    return user ? JSON.parse(user) : null
  }

  setUser(response: any) {
    if (response && response.user) {
      sessionStorage.setItem('user', JSON.stringify(response.user))
      this.login$.next(true)
    } else {
      sessionStorage.removeItem('user')
    }
  }

  handleError(errorResponse: HttpErrorResponse) {
    // istanbul ignore next
    this.error$.next(errorResponse.error.error.alias ?? errorResponse.message)
    return throwError(errorResponse)
  }

  login(credentials: any, loginType: LoginTypes): Observable<any> {
    return this.http
      .post(`/api/v1/users/login/${loginType}`, { credentials })
      .pipe(tap(this.setUser.bind(this)), catchError(this.handleError.bind(this)))
  }

  authorizeBank(data: Bank): Observable<any> {
    return this.http
      .post(`/api/public/v1/users/authorize/bank`, { data })
      .pipe(tap(this.setUser.bind(this)), catchError(this.handleError.bind(this)))
  }

  logout(): Observable<any> {
    const user = this.user
    // istanbul ignore next
    const token = user?.token ?? ''
    this.setUser({ user: null })
    this.logout$.next(true)
    return user
      ? this.http.post(
          `/api/public/v1/users/logout`,
          {},
          {
            params: { token },
          },
        )
      : new Observable()
  }

  isAuthenticated(): boolean {
    return Boolean(this.user ?? false)
  }

  // istanbul ignore next
  ngOnDestroy() {
    this.error$.unsubscribe()
    this.login$.unsubscribe()
    this.logout$.unsubscribe()
  }
}
